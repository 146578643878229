@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Impact';
  src: url('../public/fonts/ImpactLTStd.woff2') format('woff2'),
       url('../public/fonts/ImpactLTStd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fraunces';
  src: url('../public/fonts/Fraunces-Italic-VariableFont_SOFT\,WONK\,opsz\,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




#root {
  z-index: -1;
  opacity: 1;
}

/* html {
  height: 100%;
} */

body {
  z-index: 1;
  top: 0 !important;
}

body::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: var(--design_Background_colour);
  background-image: var(--design_background_png_URL);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
  opacity: var(--opacity);
  background-position: center;
}

/* iframe {
  width: 100%;
  height: 85vh;
} */

.theme-bg-image {
  border: solid 2px;
  border-color: var(--design_text_colour);
  background-color: var(--design_Background_colour);
  background-image: var(--design_background_png_URL);
  background-repeat: no-repeat;
  background-size: cover;
}
.theme-bg-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: var(--back_opacity);
}

.theme-bg-color {
  background-color: var(--design_Background_colour);
}
.theme-bg-color_without_opacity {
  background-color: var(--design_Background_colour_without_opacity);
}
.theme-bg-color-invert {
  background-color: var(--design_text_colour);
}

.theme-text-color {
  color: var(--design_text_colour);
}
.theme-text-color-invert {
  color: var(--design_button_back_colour);
}
.theme-social-color {
  color: var(--design_social_colour);
}

.theme-border-color {
  border-color: var(--design_text_colour);
}

.theme-button-background {
  background-color: var(--design_button_back_colour);
}

.theme-button-background-without-opacity {
  background-color: var(--design_button_back_colour_without_opacity);
}

.footer-text-color {
  color: var(--design_social_colour);
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--design_social_colour) !important;
}

.swiper-pagination {
  position: relative !important;
  color: brown !important;
}

/* .swiper-wrapper {
  align-items: center;
} */

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--design_social_colour) !important;
}

.bottom-text {
  color: var(--design_button_back_colour) !important;
}

.icon-wrapper {
  display: flex;
  margin: 10px auto;
  justify-content: center;
  flex-wrap: wrap;
}

.icon-wrapper .list-icon {
  padding: 10px;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 80px;
  max-width: 12.5%;
  min-width: 12.5%;
}

.swiper {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-color-payment {
  filter: var(--design_Icon_colour_payment);
  opacity: var(--design_Icon_colour_opacity);
}

@media (min-width: 1300px) {
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-color {
    filter: var(--design_Icon_colour);
  }

  .swipe-img {
    position: relative;
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 1300px) {
  .container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-wrapper .list-icon {
    max-width: 50%;
    min-width: 50%;
  }

  .icons {
    width: 100px;
    height: 100px;
  }
  .icon-color {
    filter: var(--design_Icon_colour);
  }
  .swipe-img {
    position: relative;
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 800px) {
  .container {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icons {
    width: 50px;
    height: 50px;
  }

  .icon-color {
    filter: var(--design_Icon_colour);
  }
  .swipe-img {
    position: relative;
    height: 80px;
    width: 80px;
  }
}

@media (max-width: 300px) {
  .container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-color {
    filter: var(--design_Icon_colour);
  }
  .swipe-img {
    position: relative;
    height: 80px;
    width: 80px;
  }
}

.list-icon.active {
  background: #f6dc0685;
}

.list-icon.disabled {
  cursor: not-allowed !important;
}

.center-section {
  min-height: 550px;
  overflow-y: auto;;
  position: relative;
}

.center-section::-webkit-scrollbar {
  display: none;
}

.social-icon-color {
  filter: var(--design_social_Icon_colour);
}

.payment-text-color {
  color: var(--design_social_colour);
}

.social-icon-color-trans {
  filter: var(--design_social_Icon_colour);
  opacity: 80%;
}

.bottom-icon {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}
.swiper {
  width: 100%;
  height: 200px;
  overflow: visible;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  /* z-index: 2; */
  transition: opacity 0.8s ease-in-out;
}

.swiper-slide-active {
  /* scale: 2; */
  z-index: 2;
}
.swiper-slide-prev {
  scale: 0.75;
  opacity: 50%;
}
.swiper-slide-next {
  scale: 0.75;
  opacity: 50%;
}

.content {
  text-align: left;
  margin: auto;
  position: relative;
}

@media (max-width: 800px) {
  video {
    max-height: 35vh !important;
  }
}

@media (max-width: 400px) {
  video {
    max-height: 35vh !important;
  }
}

.send-cam-photo {
  height: 50%;
  width: 40%;
}

.pop-wrap {
  max-width: 600px;
  width: 100% !important;
  height: inherit;
  z-index: 9999;
}

.pop-inner {
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
}

.cm-out-wrap {
  flex-wrap: wrap;
  padding-top: 10px;
  width: 100%;
}

.react-html5-camera-photo {
  margin-bottom: 10px;
}

.pop-inner input {
  border: 1px solid #000;
  outline: none;
}

#display-error {
  color: #000;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
  padding: 10px;
}

.box-wrap-inner {
  box-sizing: border-box;
  height: 95%;
}

.google-ads {
  width: 100%;
  left: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .react-html5-camera-photo .font-semibold {
    font-size: 1rem;
  }

  .font-semibold {
    font-size: 1rem;
    line-height: 1.2;
  }

  .cm-out-wrap button {
    font-size: 13px;
  }

  .pop-inner video {
    max-height: 200px !important;
  }

  .pop-wrap {
    padding: 10px;
  }

  div#inner-circle {
    height: 30px;
    left: 50%;
    margin: 0;
    top: 13%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    width: 30px;
  }

  div#outer-circle {
    height: 40px;
    width: 40px;
    position: relative;
    left: 0;
  }

  #container-circles {
    bottom: 5px !important;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  #inner-circle.is-clicked {
    height: 30px;
    margin: 0;
    width: 30px;
  }

  .react-html5-camera-photo {
    flex: 0 0 100%;
  }
  .send-cam-photo {
    height: 120px;
    object-fit: cover;
    width: 120px;
  }
  .wr-wrap .text-7xl {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
  }

  .wr-wrap .text-3xl {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }

  .box-wrap-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .error {
    color: rgb(113, 27, 27);
  }
}
.back-opacity {
  opacity: var(--opacity);
}
.bm-menu {
  background: #fff;
  z-index: 1;
}
.bm-overlay {
  background: #fff !important;
}

.slide-menu {
  position: fixed;
  top: 25%;
  right: 5vw; /* Initially hidden outside the viewport */
  width: 90%;
  /* max-width: 55%; */
  background-color: #fff;
  transition: right ease-in 0.3s;
  z-index: 99999;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* Ensure the pseudo-element does not overflow */
  border-color: var(--design_social_colour);
}

.slide-menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--design_background_png_URL);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2; /* Set the desired opacity */
  z-index: -1; /* Ensure the pseudo-element is behind the content */
}


.slide-menu.open {
  right: 0; /* Slide into view */
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 24px;
  cursor: pointer;
}

.vcard {
  aspect-ratio: 8/5;
}

.phone-input {
  width: 275px !important;
  padding-left: 16% !important;
  color: #000;

}

.country-list {
  text-align: left;
  color: #000;
  width: 250px !important;
}

.react-tel-input {
  width: 300px !important;

}
.containers {
  position: relative;
}

.containers img:hover {
  opacity: 0.3;
}

.containers:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.vertical {
  display: flex;
  align-items: flex-start;
  min-height: 90vh;
  max-height: 90vh;
}

.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  object-fit: contain;
  vertical-align: middle !important;
  max-height: 90vh;
  width: 80vw;
}
.my-masonry-grid {
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-item {
  background: #efefef;
  /* margin-bottom: 10px; */
  /* padding: 5px; */
  border-radius: 5px;
}

.my-masonry-item img {
  /* width: 100%; */
  display: block;
  border-radius: 5px;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial", cursive;
  content: "Arial" !important;
}

.ql-align-center{
  text-align: center;
}

.ql-align-left{
  text-align: left;
}

.ql-align-right{
  text-align: right;
}


.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
  font-family: "Verdana", cursive;
  content: "Verdana" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before {
  font-family: "Helvetica", cursive;
  content: "Helvetica" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Times-New-Roman"]::before {
  font-family: "Times New Roman", cursive;
  content: "Times New Roman" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before {
  font-family: "Georgia", cursive;
  content: "Georgia" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Courier-New"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Courier-New"]::before {
  font-family: "Courier New", cursive;
  content: "Courier New" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Impact"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Impact"]::before {
  font-family: "Impact", cursive;
  content: "Impact" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Comic-Sans-MS "]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Comic-Sans-MS"]::before {
  font-family: "Comic Sans MS", cursive;
  content: "Comic Sans MS" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lucida-Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lucida-Sans"]::before {
  font-family: "Lucida Sans", cursive;
  content: "Lucida Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Trebuchet-MS"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Trebuchet-MS"]::before {
  font-family: "Trebuchet MS", cursive;
  content: "Trebuchet MS" !important;
}

/* Set content font-families */
.ql-font-Arial {
  font-family: "Arial";
}
.ql-font-Verdana {
  font-family: "Verdana";
}
.ql-font-Helvetica {
  font-family: "Helvetica";
}
.ql-font-Times-New-Roman {
  font-family: "Times New Roman";
}
.ql-font-Georgia {
  font-family: "Georgia";
}
.ql-font-Courier-New {
  font-family: "Courier New";
}
.ql-font-Impact {
  font-family: "Impact";
}
.ql-font-Comic-Sans-MS {
  font-family: "Comic Sans MS";
}
.ql-font-Lucida-Sans {
  font-family: "Lucida Sans";
}
.ql-font-Trebuchet-MS {
  font-family: "Trebuchet MS";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before {
  content: "8px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before {
  content: "9px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "8px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
  content: "24px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before {
  content: "32px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="42px"]::before {
  content: "42px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="54px"]::before {
  content: "54px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="68px"]::before {
  content: "68px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="72px"]::before {
  content: "72px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8px";
  font-size: 8px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9px";
  font-size: 9px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
  font-size: 32px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="42px"]::before {
  content: "42px";
  font-size: 42px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="54px"]::before {
  content: "54px";
  font-size: 54px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="68px"]::before {
  content: "68px";
  font-size: 68px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  content: "72px";
  font-size: 72px !important;
}

.impact {
  font-family: "Impact", 'Arial Black', sans-serif;

}

.slider-text {
  font-family: 'Impact', sans-serif;
}

.dropdown-options::-webkit-scrollbar {
  display: none;  /* Hide scrollbar in WebKit-based browsers */
}

.skiptranslate {
    display: none !important;
}